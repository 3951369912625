/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiJegy } from './allamiJegy';
import { FogasiNaplo } from './fogasiNaplo';
import { HorgaszAdatSzemely } from './horgaszAdatSzemely';
import { SzemelyEltiltas } from './szemelyEltiltas';
import { SzemelyTeruletiEltiltas } from './szemelyTeruletiEltiltas';
import { TeruletiJegy } from './teruletiJegy';
import { VersenyzoiEngedely } from './versenyzoiEngedely';

export interface EllenorzottSzemelyAdatai { 
    allamiJegyList?: Array<AllamiJegy>;
    ervenyesAllamiJegy?: AllamiJegy;
    ervenyesFogasiNaplo?: FogasiNaplo;
    ervenyesTeruletiJegy?: TeruletiJegy;
    ervenyesVersenyzoiEngedely?: VersenyzoiEngedely;
    fogasiNaploList?: Array<FogasiNaplo>;
    horgasz?: HorgaszAdatSzemely;
    horgaszAzonosito?: string;
    keresettEgyediAzonosito?: string;
    szemelyEltiltas?: SzemelyEltiltas;
    szemelyTeruletiEltiltas?: SzemelyTeruletiEltiltas;
    szemelyTeruletiEltiltasList?: Array<SzemelyTeruletiEltiltas>;
    teruletiJegyList?: Array<TeruletiJegy>;
    versenyzoiEngedelyList?: Array<VersenyzoiEngedely>;
}