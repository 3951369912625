/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiJegy } from './allamiJegy';
import { FogasiNaplo } from './fogasiNaplo';
import { HorgaszAdatSzemely } from './horgaszAdatSzemely';
import { SzemelyEltiltas } from './szemelyEltiltas';
import { SzemelyTeruletiEltiltas } from './szemelyTeruletiEltiltas';
import { TeruletiJegy } from './teruletiJegy';
import { VersenyzoiEngedely } from './versenyzoiEngedely';

export interface HorgaszAdatReply { 
    allamiJegyList?: Array<AllamiJegy>;
    ellenorzesiTevekenysegId?: number;
    ervenyesAllamiJegy?: AllamiJegy;
    ervenyesFogasiNaplo?: FogasiNaplo;
    ervenyesTeruletiJegy?: TeruletiJegy;
    ervenyesVersenyzoiEngedely?: VersenyzoiEngedely;
    fogasiNaploList?: Array<FogasiNaplo>;
    hanyadikErvenyesAllamiJegy?: number;
    hanyadikErvenyesFogasiNaplo?: number;
    horgaszAzonosito?: string;
    idopont?: Date;
    szemely?: HorgaszAdatSzemely;
    szemelyEltiltas?: SzemelyEltiltas;
    szemelyTeruletiEltiltas?: SzemelyTeruletiEltiltas;
    szemelyTeruletiEltiltasList?: Array<SzemelyTeruletiEltiltas>;
    teruletiJegyList?: Array<TeruletiJegy>;
    uniqueIds?: Array<string>;
    versenyzoiEngedelyList?: Array<VersenyzoiEngedely>;
}