export * from './allamiJegy';
export * from './allamiJegyArDimenzio';
export * from './batchEllenorLokacioRogzitesRequest';
export * from './batchHorgaszAdatLekerdezesRequest';
export * from './cim';
export * from './ellenorLokacioRogzitesRequest';
export * from './ellenorSssUser';
export * from './ellenorzesiTevekenyseg';
export * from './ellenorzesiTevekenysegFilterRequest';
export * from './ellenorzesiTevekenysegKeresesRequest';
export * from './ellenorzesiTevekenysegKontextus';
export * from './ellenorzesiTevekenysegMinositesRequest';
export * from './ellenorzesiTevekenysegReply';
export * from './ellenorzesiTevekenysegReplyRow';
export * from './ellenorzottSzemelyAdatai';
export * from './felhasznaloMeghivasRequest';
export * from './fogasiNaplo';
export * from './gPSKoordinata';
export * from './horgaszAdatReply';
export * from './horgaszAdatRequest';
export * from './horgaszAdatSzemely';
export * from './jogosultsag';
export * from './jogosultsagTorlesRequest';
export * from './megbizoDetails';
export * from './megbizoJogosultsagBundle';
export * from './megbizoKivalasztasRequest';
export * from './personalizedJogosultsag';
export * from './sssUser';
export * from './szemelyEltiltas';
export * from './szemelyTeruletiEltiltas';
export * from './szereploRequest';
export * from './teruletiJegy';
export * from './teruletiJegyOnlineSztornoDetails';
export * from './teruletiKiegeszitoJegy';
export * from './versenyzoiEngedely';
export * from './vizteruletHorgaszaiRequest';
export * from './vizteruletRequest';
export * from './vizteruletTorzsadat';
export * from './vizteruletView';
export * from './vizteruletViewItem';
