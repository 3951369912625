/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FogasiNaplo { 
    availableCommands?: Array<FogasiNaplo.AvailableCommandsEnum>;
    elektronikus?: boolean;
    ertekesitoSzervezetId?: number;
    ervenyessegDatuma?: string;
    ervenyessegiEv: number;
    fogasRogzitesDatuma?: string;
    hasTortenet?: boolean;
    hianyzoRogzitheto?: boolean;
    igazolasKepId?: number;
    leadasDatuma?: string;
    leadasVisszavonhato?: boolean;
    leadhato?: boolean;
    leadottFogasiNaploKepIds?: Array<number>;
    nyomtathato?: boolean;
    onalloTermek?: boolean;
    osszesitesKitoltve?: boolean;
    sorszam: string;
    statusz: FogasiNaplo.StatuszEnum;
    sztornozasDatuma?: Date;
    tipus?: FogasiNaplo.TipusEnum;
    torolheto?: boolean;
    vasarlasDatuma?: Date;
    visszavonasDatuma?: string;
}
export namespace FogasiNaplo {
    export type AvailableCommandsEnum = 'HIANYZO_ROGZITES' | 'HIANYZO_ROGZITES_VISSZAVON' | 'LEADAS' | 'TURISTA_LEADAS' | 'LEADAS_VISSZAVON' | 'TORLES' | 'FOGAS_ROGZITES' | 'FOGAS_MEGTEKINTES' | 'TURISTA_NYOMTATAS' | 'SZTORNO' | 'SZTORNO_VISSZAVON' | 'ESZTORNO';
    export const AvailableCommandsEnum = {
        HIANYZOROGZITES: 'HIANYZO_ROGZITES' as AvailableCommandsEnum,
        HIANYZOROGZITESVISSZAVON: 'HIANYZO_ROGZITES_VISSZAVON' as AvailableCommandsEnum,
        LEADAS: 'LEADAS' as AvailableCommandsEnum,
        TURISTALEADAS: 'TURISTA_LEADAS' as AvailableCommandsEnum,
        LEADASVISSZAVON: 'LEADAS_VISSZAVON' as AvailableCommandsEnum,
        TORLES: 'TORLES' as AvailableCommandsEnum,
        FOGASROGZITES: 'FOGAS_ROGZITES' as AvailableCommandsEnum,
        FOGASMEGTEKINTES: 'FOGAS_MEGTEKINTES' as AvailableCommandsEnum,
        TURISTANYOMTATAS: 'TURISTA_NYOMTATAS' as AvailableCommandsEnum,
        SZTORNO: 'SZTORNO' as AvailableCommandsEnum,
        SZTORNOVISSZAVON: 'SZTORNO_VISSZAVON' as AvailableCommandsEnum,
        ESZTORNO: 'ESZTORNO' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'KIADOTT' | 'LEADOTT' | 'BETELT' | 'MEGSEMMISULT' | 'ELVESZETT' | 'VEGLEGESEN_STORNOZOTT' | 'KIADOTT_SZTORNO_JOVAHAGYASRA_VAR' | 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' | 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'VISSZAVONT' | 'UJRAFELHASZNALHATO_SZTORNOZOTT' | 'SZTORNOZOTT';
    export const StatuszEnum = {
        KIADOTT: 'KIADOTT' as StatuszEnum,
        LEADOTT: 'LEADOTT' as StatuszEnum,
        BETELT: 'BETELT' as StatuszEnum,
        MEGSEMMISULT: 'MEGSEMMISULT' as StatuszEnum,
        ELVESZETT: 'ELVESZETT' as StatuszEnum,
        VEGLEGESENSTORNOZOTT: 'VEGLEGESEN_STORNOZOTT' as StatuszEnum,
        KIADOTTSZTORNOJOVAHAGYASRAVAR: 'KIADOTT_SZTORNO_JOVAHAGYASRA_VAR' as StatuszEnum,
        KIADOTTELVESZETTJOVAHAGYASRAVAR: 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' as StatuszEnum,
        KIADOTTMEGSEMMISULTJOVAHAGYASRAVAR: 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' as StatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as StatuszEnum,
        UJRAFELHASZNALHATOSZTORNOZOTT: 'UJRAFELHASZNALHATO_SZTORNOZOTT' as StatuszEnum,
        SZTORNOZOTT: 'SZTORNOZOTT' as StatuszEnum
    };
    export type TipusEnum = 'TURISTA_FOGASINAPLO' | 'FOGASINAPLO' | 'E_TURISTA_FOGASINAPLO' | 'E_FOGASINAPLO';
    export const TipusEnum = {
        TURISTAFOGASINAPLO: 'TURISTA_FOGASINAPLO' as TipusEnum,
        FOGASINAPLO: 'FOGASINAPLO' as TipusEnum,
        ETURISTAFOGASINAPLO: 'E_TURISTA_FOGASINAPLO' as TipusEnum,
        EFOGASINAPLO: 'E_FOGASINAPLO' as TipusEnum
    };
}