/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EllenorSssUser { 
    active?: boolean;
    authorities?: Array<string>;
    authorityChannel?: string;
    email?: string;
    entrusting?: number;
    entrustingId?: number;
    entrustingNev?: string;
    lastLoggedIn?: Date;
    loginId?: string;
    password?: string;
    trusteeId?: number;
    vizteruletId?: number;
    vizteruletNev?: string;
}