import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {SQLitePorter} from '@ionic-native/sqlite-porter/ngx';
import {SQLite, SQLiteObject} from '@ionic-native/sqlite/ngx';
import {HttpClient} from '@angular/common/http';
import {DatabaseHorgaszAdatObject} from '../objects/databaseHorgaszAdatObject';
import {DatabaseEllenorLokalizacioObject} from '../objects/databaseEllenorLokalizacioObject';
import {EllenorLokacioRogzitesRequest, HorgaszAdatRequest} from 'src/api';
import {AppHelper} from "./app-helper";

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  private database: SQLiteObject;
  private dbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ellenorzesek: DatabaseHorgaszAdatObject[] = [];

  private INSERT_LOKACIO_STMT = 'insert into lokacio (ellenor_szemely_id,szervezet_id,idopont,koordinata) values (?,?,?,?)'
  private INSERT_ELLENORZES_STMT = 'insert into ellenorzes (ellenor_szemely_id, szervezet_id, vizterulet_id, ' +
    'kliens_idopont, hosszusagi_fok, szelessegi_fok, egyedi_azonosito, vizterulet_nev) values (?,?,?,?,?,?,?,?)';

  private ROLLBACK_LOKACIO_STMT = 'insert into lokacio (id, ellenor_szemely_id,szervezet_id,idopont,koordinata) values (?,?,?,?,?)'
  private ROLLBACK_ELLENORZES_STMT = 'insert into ellenorzes (id, ellenor_szemely_id, szervezet_id, vizterulet_id, ' +
    'kliens_idopont, hosszusagi_fok, szelessegi_fok, egyedi_azonosito, vizterulet_nev) values (?,?,?,?,?,?,?,?,?)';

  constructor(
    private plt: Platform,
    private sqlitePorter: SQLitePorter,
    private sqlite: SQLite,
    private http: HttpClient
  ) {
    if (this.plt) {
      this.plt.ready().then(() => {
        // this.sqlite.deleteDatabase({
        //   name: 'mohoszellenor.db',
        //   location: 'default'
        // });
        this.sqlite.create({
          name: 'mohoszellenor.db',
          location: 'default'
        }).then((db: SQLiteObject) => {
          this.database = db;
          this.seedDatabase();
        }, err => {
          console.error(err);
        }).catch(error => console.log(error));
      }).catch(error => console.log(error));
    }
  }

  private async executeAsset(statement: string): Promise<any> {
    try {
      const asset = await this.http.get(statement, {responseType: 'text'}).toPromise();
      return this.sqlitePorter.importSqlToDb(this.database, asset);
    } catch (error) {
      console.error(error);
    }
  }

  async seedDatabase() {
    const promises = [];
    try {
      promises.push(this.executeAsset('assets/ddl/ddl-ellenorzes.sql'));
      promises.push(this.executeAsset('assets/ddl/ddl-lokacio.sql'));
      await Promise.all(promises);
      this.dbReady.next(true);
      await this.loadEllenorzesek();
    } catch (error) {
      alert('Init Databases' + JSON.stringify(error));
    }
  }

  getDatabaseState() {
    return this.dbReady.asObservable();
  }

  static toEllenorzesekDomain(dataRow: any): DatabaseHorgaszAdatObject {
    return {
      id: dataRow.id,
      kliensIdopont: dataRow.kliens_idopont ? AppHelper.toServerDate(new Date(dataRow.kliens_idopont)) : null,
      egyediAzonosito: dataRow.egyedi_azonosito,
      ellenorSzemelyId: dataRow.ellenor_szemely_id,
      szervezetId: dataRow.szervezet_id,
      vizteruletId: dataRow.vizterulet_id,
      hosszusagiFok: dataRow.hosszusagi_fok,
      szelessegiFok: dataRow.szelessegi_fok,
      vizteruletNev: dataRow.vizterulet_nev
    }
  }

  static toLokaciokDomain(dataRow: any): DatabaseEllenorLokalizacioObject {
    return {
      id: dataRow.id,
      idopont: dataRow.idopont ? AppHelper.toServerDate(new Date(dataRow.idopont)) : null,
      ellenorSzemelyId: dataRow.ellenor_szemely_id,
      szervezetId: dataRow.szervezet_id,
      koordinata: JSON.parse(dataRow.koordinata),
    };
  }

  async loadLokaciokForSync(): Promise<Array<DatabaseEllenorLokalizacioObject>> {
    const lokaciok: Array<DatabaseEllenorLokalizacioObject> = [];
    if (this.database) {
      await this.database.transaction(tx => {
        tx.executeSql('select * from lokacio', [],
          (tx, result) => {
            for (let i = 0; i < result.rows.length; i++) {
              lokaciok.push(DatabaseService.toLokaciokDomain(result.rows.item(i)));
              //kitoroljuk, es ha a server hibara fut akkor ismet beszurjuk
              tx.executeSql('delete from lokacio where id = ?', [lokaciok[i].id],
                (tx, result) => {
                },
                error => console.error(error)
              );
            }
          }, error => console.error(error))
      })
    }
    return lokaciok;
  }

  async loadEllenorzesekForSync(): Promise<Array<DatabaseHorgaszAdatObject>> {
    const ellenorzesek: Array<DatabaseHorgaszAdatObject> = [];
    if (this.database) {
      await this.database.transaction(tx => {
        tx.executeSql('select * from ellenorzes', [],
          (tx, result) => {
            for (let i = 0; i < result.rows.length; i++) {
              ellenorzesek.push(DatabaseService.toEllenorzesekDomain(result.rows.item(i)));
              //kitoroljuk, es ha a server hibara fut akkor ismet beszurjuk
              tx.executeSql('delete from ellenorzes where id = ?', [ellenorzesek[i].id],
                (tx, result) => {
                },
                error => console.error(error)
              );
            }
          }, error => console.error(error))
      })
    }
    this.loadEllenorzesek()
    return ellenorzesek
  }

  insertLokacio(lokacio: EllenorLokacioRogzitesRequest) {
    const data = [
      lokacio.ellenorSzemelyId,
      lokacio.szervezetId,
      lokacio.idopont,
      JSON.stringify(lokacio.koordinata)
    ];
    return this.database.transaction(tx => tx.executeSql(this.INSERT_LOKACIO_STMT, data, () => {
    }, error => console.error(error)))
  }

  rollbackLokacioList(lokacioList: Array<DatabaseEllenorLokalizacioObject>) {
    return this.database.transaction(tx => {
        for (let i = 0; i < lokacioList.length; i++) {
          const data = [
            lokacioList[i].id,
            lokacioList[i].ellenorSzemelyId,
            lokacioList[i].szervezetId,
            lokacioList[i].idopont,
            JSON.stringify(lokacioList[i].koordinata)
          ]
          tx.executeSql(this.ROLLBACK_LOKACIO_STMT, data, () => {
          }, error => console.error(error))
        }
      }
    )
  }

  insertEllenorzes(vizteruletNev: string, ellenorzes: HorgaszAdatRequest) {
    const data = [
      ellenorzes.ellenorSzemelyId,
      ellenorzes.szervezetId,
      ellenorzes.vizteruletId,
      ellenorzes.kliensIdopont,
      ellenorzes.hosszusagiFok,
      ellenorzes.szelessegiFok,
      ellenorzes.egyediAzonosito,
      vizteruletNev
    ];
    return this.database.transaction(tx => {
      tx.executeSql(this.INSERT_ELLENORZES_STMT, data, (tx, result) => this.loadEllenorzesek(), error => console.error(error))
    })
  }

  rollbackEllenorzesList(ellenorzesList: Array<DatabaseHorgaszAdatObject>) {
    return this.database.transaction(tx => {
        for (let i = 0; i < ellenorzesList.length; i++) {
          const data = [
            ellenorzesList[i].id,
            ellenorzesList[i].ellenorSzemelyId,
            ellenorzesList[i].szervezetId,
            ellenorzesList[i].vizteruletId,
            ellenorzesList[i].kliensIdopont,
            ellenorzesList[i].hosszusagiFok,
            ellenorzesList[i].szelessegiFok,
            ellenorzesList[i].egyediAzonosito
          ]
          tx.executeSql(this.ROLLBACK_ELLENORZES_STMT, data, () => {
          }, error => console.error(error))
        }
        this.loadEllenorzesek()
      }
    )
  }

  async loadEllenorzesek() {
    try {
      if (this.database) {
        const data = await this.database.executeSql('select * from ellenorzes', []);
        const ellenorzesek: DatabaseHorgaszAdatObject[] = [];
        if (data.rows.length > 0) {
          for (let i = 0; i < data.rows.length; i++) {
            ellenorzesek.push(DatabaseService.toEllenorzesekDomain(data.rows.item(i)))
          }
        }
        this.ellenorzesek = ellenorzesek;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
