/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HorgaszAdatRequest { 
    egyediAzonosito: string;
    ellenorSzemelyId: number;
    hosszusagiFok: number;
    kliensIdopont?: Date;
    szelessegiFok: number;
    szervezetId: number;
    validEllenorSzemelyId?: boolean;
    validSzervezetId?: boolean;
    validVizteruletId?: boolean;
    vizteruletId: number;
}