/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BatchHorgaszAdatLekerdezesRequest } from '../model/batchHorgaszAdatLekerdezesRequest';
import { EllenorSssUser } from '../model/ellenorSssUser';
import { EllenorzesiTevekenyseg } from '../model/ellenorzesiTevekenyseg';
import { EllenorzesiTevekenysegFilterRequest } from '../model/ellenorzesiTevekenysegFilterRequest';
import { EllenorzesiTevekenysegKeresesRequest } from '../model/ellenorzesiTevekenysegKeresesRequest';
import { EllenorzesiTevekenysegMinositesRequest } from '../model/ellenorzesiTevekenysegMinositesRequest';
import { EllenorzesiTevekenysegReply } from '../model/ellenorzesiTevekenysegReply';
import { HorgaszAdatReply } from '../model/horgaszAdatReply';
import { HorgaszAdatRequest } from '../model/horgaszAdatRequest';
import { VizteruletHorgaszaiRequest } from '../model/vizteruletHorgaszaiRequest';
import { VizteruletRequest } from '../model/vizteruletRequest';
import { VizteruletTorzsadat } from '../model/vizteruletTorzsadat';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OnlineEllenorControllerService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public batchHorgaszAdatKereses(body: BatchHorgaszAdatLekerdezesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public batchHorgaszAdatKereses(body: BatchHorgaszAdatLekerdezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public batchHorgaszAdatKereses(body: BatchHorgaszAdatLekerdezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public batchHorgaszAdatKereses(body: BatchHorgaszAdatLekerdezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling batchHorgaszAdatKereses.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/onlineellenor/batch-horgaszadat-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszAdatKereses(body: HorgaszAdatRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszAdatReply>;
    public horgaszAdatKereses(body: HorgaszAdatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszAdatReply>>;
    public horgaszAdatKereses(body: HorgaszAdatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszAdatReply>>;
    public horgaszAdatKereses(body: HorgaszAdatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszAdatKereses.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszAdatReply>('post',`${this.basePath}/api/mohosz/onlineellenor/horgaszadat-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszAdatLekeredezes(body: EllenorzesiTevekenysegKeresesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszAdatReply>;
    public horgaszAdatLekeredezes(body: EllenorzesiTevekenysegKeresesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszAdatReply>>;
    public horgaszAdatLekeredezes(body: EllenorzesiTevekenysegKeresesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszAdatReply>>;
    public horgaszAdatLekeredezes(body: EllenorzesiTevekenysegKeresesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszAdatLekeredezes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszAdatReply>('post',`${this.basePath}/api/mohosz/onlineellenor/horgaszadat-lekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tevekenysegKereses(body: EllenorzesiTevekenysegFilterRequest, observe?: 'body', reportProgress?: boolean): Observable<EllenorzesiTevekenysegReply>;
    public tevekenysegKereses(body: EllenorzesiTevekenysegFilterRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EllenorzesiTevekenysegReply>>;
    public tevekenysegKereses(body: EllenorzesiTevekenysegFilterRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EllenorzesiTevekenysegReply>>;
    public tevekenysegKereses(body: EllenorzesiTevekenysegFilterRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling tevekenysegKereses.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<EllenorzesiTevekenysegReply>('post',`${this.basePath}/api/mohosz/onlineellenor/tevekenyseg-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tevekenysegMinosites(body: EllenorzesiTevekenysegMinositesRequest, observe?: 'body', reportProgress?: boolean): Observable<EllenorzesiTevekenyseg>;
    public tevekenysegMinosites(body: EllenorzesiTevekenysegMinositesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EllenorzesiTevekenyseg>>;
    public tevekenysegMinosites(body: EllenorzesiTevekenysegMinositesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EllenorzesiTevekenyseg>>;
    public tevekenysegMinosites(body: EllenorzesiTevekenysegMinositesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling tevekenysegMinosites.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<EllenorzesiTevekenyseg>('post',`${this.basePath}/api/mohosz/onlineellenor/tevekenyseg-minosites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoVizteruletek(observe?: 'body', reportProgress?: boolean): Observable<Array<VizteruletTorzsadat>>;
    public valaszthatoVizteruletek(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizteruletTorzsadat>>>;
    public valaszthatoVizteruletek(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizteruletTorzsadat>>>;
    public valaszthatoVizteruletek(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VizteruletTorzsadat>>('post',`${this.basePath}/api/mohosz/onlineellenor/valaszthato-vizteruletek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletHorgaszai(body: VizteruletHorgaszaiRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszAdatReply>>;
    public vizteruletHorgaszai(body: VizteruletHorgaszaiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszAdatReply>>>;
    public vizteruletHorgaszai(body: VizteruletHorgaszaiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszAdatReply>>>;
    public vizteruletHorgaszai(body: VizteruletHorgaszaiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizteruletHorgaszai.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszAdatReply>>('post',`${this.basePath}/api/mohosz/onlineellenor/vizterulet-horgaszai`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletKivalasztas(body: VizteruletRequest, observe?: 'body', reportProgress?: boolean): Observable<EllenorSssUser>;
    public vizteruletKivalasztas(body: VizteruletRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EllenorSssUser>>;
    public vizteruletKivalasztas(body: VizteruletRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EllenorSssUser>>;
    public vizteruletKivalasztas(body: VizteruletRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizteruletKivalasztas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<EllenorSssUser>('post',`${this.basePath}/api/mohosz/onlineellenor/vizterulet-kivalasztas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
