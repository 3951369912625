/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Cim { 
    ajto?: string;
    alternativCimzett?: string;
    emelet?: string;
    hazszam: string;
    iranyitoszam: string;
    kozteruletJelleg?: string;
    kozteruletNev?: string;
    kozteruletNevMustNull?: boolean;
    megjegyzes?: string;
    megyeKod?: string;
    orszagKod: string;
    telepules: string;
    validIranyitoszam?: boolean;
}